<template>
  <div class="none-list">
    <img :src="require('@/assets/apply-list-none.png')" alt="" />
    <span class="info">{{ msg }}</span>
  </div>
</template>

<script>
export default {
  name: 'DefaultImg',
  components: {},

  props: {
    msg: {
      type: String,
      default: '暂无数据'
    }
  },

  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
.none-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 10px;
  img {
    margin-top: 105px;
    width: 280px;
    height: 150px;
  }
  .info {
    color: #666666;
    font-size: 16px;
    margin-top: 50px;
    font-family: 'PingFangSC-Regular, sans-serif';
  }
}
</style>
