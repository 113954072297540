<template>
  <div class="card-container">
    <headTitle title="卡管理"></headTitle>
    <!-- 使用 v-if 检查 loading 状态 -->
    <div v-if="loading" class="loading-placeholder"></div>
    <div v-else>
      <!-- 使用 v-if 检查 cardDetail 是否有数据 -->
      <div style="padding-bottom: 20px" v-if="cardDetail.length > 0">
        <div class="card-info" v-for="item in cardDetail" :key="item.id">
          <div class="card-info-title">
            <span>持卡人</span>
            <span>
              {{ item.memberName || name }}
            </span>
          </div>
          <div class="card-info-item">
            <span>状态</span>
            <span :class="item.status === 1 ? 'normal' : 'loss'">
              {{ item.status === 1 ? '正常' : '挂失' }}
            </span>
          </div>
          <div class="card-info-item">
            <span>卡号</span>
            <span class="right">
              {{ item.cardSn }}
            </span>
          </div>
          <div class="card-info-item">
            <span>卡费</span>
            <span class="right">
              <span style="margin-right: 2px">¥</span>
              {{ item.cardMoney / 100 }}
            </span>
          </div>
          <div class="card-info-item">
            <span>押金</span>
            <span class="right">
              <span style="margin-right: 2px">¥</span>
              {{ item.deposit / 100 }}
            </span>
          </div>
          <div class="card-info-btn">
            <div
              :class="item.status === 1 ? 'btn' : 'btn disable'"
              @click="handleCard(item)"
            >
              {{ item.status === 1 ? '挂失' : '解挂' }}
            </div>
          </div>
        </div>
      </div>

      <defaultImg v-else msg="您还未拥有卡,请联系管理员办理"></defaultImg>
    </div>
    <van-overlay :show="loading"></van-overlay>

    <!-- 提示弹窗 -->
    <van-dialog
      class="dialog"
      v-model="isShowLossDialog"
      showCancelButton
      cancelButtonColor="#999999"
      confirmButtonColor="#FA5050"
      confirmButtonText="确定"
      @cancel="isShowLossDialog = false"
      @confirm="lossCard"
    >
      <template>
        <div class="head">
          <div class="title">温馨提示</div>
          <div class="content">确定挂失该卡吗?</div>
        </div>
      </template>
    </van-dialog>
  </div>
</template>

<script>
import headTitle from '@/components/headTitle/index.vue';
import defaultImg from '@/components/defaultImg/index.vue';
import { getCard, cardLoss } from '@/api/card';
export default {
  name: 'CardManage',
  components: {
    headTitle,
    defaultImg
  },
  data() {
    return {
      isShowLossDialog: false,
      loading: false,
      cardDetail: [],
      name: JSON.parse(sessionStorage.getItem('personInfo')).name,
      currentId: '',
      currentRole: ''
    };
  },
  async created() {
    await this.getCardDetail();
  },
  mounted() {},
  methods: {
    async getCardDetail() {
      try {
        this.loading = true;
        const res = await getCard();
        if (res.data.length > 0) {
          this.cardDetail = res.data;
        }
        console.log(res);
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async handleCard(item) {
      if (item.status === 1) {
        this.currentId = item.id;
        this.currentRole = item.role;
        this.isShowLossDialog = true;
      } else {
        this.$toast('解挂请联系管理员');
      }
    },
    async lossCard() {
      try {
        this.loading = true;
        const res = await cardLoss(this.currentId, this.currentRole);
      } catch (e) {
        console.log(e);
      } finally {
        await this.getCardDetail();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.card-container {
  padding-top: 54px;

  .card-info {
    background-color: #ffffff;
    box-sizing: border-box;
    margin: 0 16px;
    border-radius: 10px;
    padding-left: 16px;
    margin-bottom: 15px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.08);
    .card-info-title {
      display: flex;
      justify-content: space-between;
      padding: 17px 0;
      font-size: 16px;
      color: #333333;
      font-weight: 500;
      position: relative;
      padding-right: 16px;
    }
    .card-info-title::after {
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      height: 1px;
      transform: scaleY(0.5);
      background-color: #e5e5e5;
    }
    .card-info-item {
      display: flex;
      justify-content: space-between;
      padding: 14px 0;
      font-size: 16px;
      color: #333333;
      font-weight: 400;
      padding-right: 16px;
      .right {
        color: #666666;
      }
      .normal,
      .loss {
        width: 42px;
        height: 21px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        font-size: 13px;
        font-weight: 500;
      }
      .normal {
        background-color: #00b893;
      }

      .loss {
        background-color: #ed3944;
      }
    }
    .card-info-btn {
      height: 33px;
      position: relative;
      padding: 13.5px 0;
      .btn {
        width: 77px;
        height: 33px;
        background-color: #fa5050;
        color: #ffffff;
        font-size: 16px;
        font-weight: 500;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 16px;
      }
      .disable {
        background-color: #e5e5e5;
      }
    }
  }
}
.dialog {
  width: 343px;
  height: 161px;
  border-radius: 10px;
  .head {
    box-sizing: border-box;
    height: 110px;
    padding-top: 28px;
    text-align: center;

    .title {
      color: #333333;
      font-size: 18px;
      font-family: 'PingFangSC-Regular';
      font-weight: 500;
      line-height: 18px;
    }
    .content {
      font-size: 16px;
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      color: #666666;
      margin: 24px 0;
      line-height: 16px;
    }
  }
  ::v-deep .van-button__text {
    font-size: 18px;
    font-family: 'PingFangSC-Regular';
    font-weight: 500;
    line-height: 18px;
  }
}
</style>
