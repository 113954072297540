import request from '@/utils/request';

// 查询用户的卡
export function getCard() {
  return request({
    url: `/h5/getCard`,
    method: 'get'
  });
}

// 卡挂失
export function cardLoss(cardId, params) {
  return request({
    url: `/h5/cardLoss?cardId=${cardId}&role=${params}`,
    method: 'get'
  });
}
